.app-container-fondo{
  background-color:black;
}
.app-container-navbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color:black;
  background-color: rgb(240,240,240);
  
}

.up-panel-navbar{
  flex: 1 1 30%;
  height: 30%;
  @media only screen and (max-width: 900px), only screen and (max-height: 500px){
    flex: 1 1 20%; /* Reducir tamaño en pantallas de 1600px de altura o más */
    height: 20%; /* Reducir tamaño en pantallas de 1600px de altura o más */
  }
  
}

.down-panel-navbar{
  flex: 1;
  
}

.left-panel {
  flex: 0 0 20%;
  /*background-color: rgb(215,215,215);*/
  
  
}

.right-panel {
  flex: 1;
  background-color: #e0e0e0;
  margin-left: 1px;
  color:white;
  
}

.lista-galerias-ul {
    list-style-type: none;
    padding-left: 0;
    
  }
  
  .lista-galerias-li {
    background-repeat: no-repeat;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    height: 5vh; 
    
  }

  .lista-galerias-li div{
    
    box-sizing: border-box; /* Incluye los rellenos y los bordes en el ancho */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .linkPersonalizado {
    color: black;
    text-decoration: none;
    
    border: 1px solid gray; 
    border-radius: 20px;
    padding:0.5vw;
    width: 15vw; 

    
  }
  
  .linkPersonalizado:hover {
    color: white;
    text-decoration: underline;
    background-color: rgb(190,190,190);
    border: 1px solid black;
    border-radius: 20px;
  }

  .separador-top{
    margin-top: 30px;
  }

  .separador-down{
    margin-bottom: 40px;
  }

  .social-nets{
    
    height: 20vh;
    margin: 10px;
  }

  .social-nets div{
    height: 8vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid gray;
    border-radius: 5px;
    
    color:rgba(0, 0, 0);
   
  }
  

  .social-nets div a{
    margin-left: 0.1vw;
    margin-right: 0.1vw;
    color:rgba(0, 0, 0);
  }
  .social-nets div a:hover{
    color:rgba(0, 0, 0, 0.4);
    
  }

  .boton-menu{
    
    color:black;
    border-radius: 20px;
    border: 1px solid gray;
    margin: 0 auto;
    
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2vh;
    padding:1vh;
    font-size: small;
  }

  @media only screen and (max-width: 900px), only screen and (max-height: 500px) {
   
    .left-panel, .right-panel {
      flex: 1;
      width: 100%;
    }  
   .social-nets{
      height: 10vh;  
    }

    .social-nets div{
      height: 5vh;    
    }
    .social-nets h3{
      font-size: small;    
    }
    aside{
      padding-bottom: 5vh;
    }

    .linkPersonalizado {
      font-size: small;
      width: 25vh;
      
    }    
   
  }
  
  @media only screen and (max-width: 900px) and (max-height: 500px) and (orientation: landscape) {

    .lista-galerias-li{
      
      margin-top: 3.5vh;  
    }
    .social-nets div{
      height: 6vh;    
    }

  }
  
  