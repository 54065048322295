.galeria {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
}

.galeria-container {
  height: 100%;  
  overflow-y: auto; /* Barras de desplazamiento verticales automáticas */
  color: black;
  font-size: 2.5vh;
  
}

.linea {
  margin:0px 20px;
  width:90px;    
  border-top:1px solid #999;
  position: relative;
  top:10px;
  float:left;
  }

.leyenda {
  font-weight:bold;
  float:left;
 }

 .loader {
  text-align: center;
  color:black;
}

.spin {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}