
.markdown{
   display: flex;
   align-items: center;
   justify-content: center;
   height: 85vh;
   width: 100%;
   flex-direction: column;
   
}

.markdown-texto{
   height: 70vh;
   width: 75vw;
   text-align: justify;
   font-size: medium;
   overflow-y: auto;
   color:black;  
   padding: 10px;
   margin-top: 10px;
   
}

.markdown-image{
   width: 8vw;
   height: auto;
  
}



@media only screen and (max-width: 900px), only screen and (max-height: 500px) {
   img {
     width: 15vw; /* Ancho igual al 100% del contenedor en pantallas pequeñas */
     height: auto; /* Altura automática para mantener la proporción de la imagen */
   }
   .markdown-texto{
      font-size: small;
      
   }
 }

