.titulo{
    height: 90%;
    margin: 10px;
    display: flex;
    border: 1px solid gray;
    border-radius: 5px;
    
}

.titulo-col-foto{
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.foto-circular{
    position: relative;
    width: 5vw;
    height: 5vw;
    overflow: hidden;
    border-radius: 50%; 
    @media only screen and (max-width: 900px), only screen and (max-height: 500px) {
        width: 20vw;
        height: 20vw;
      }
}



.foto-circular img {
    width: 100%;
    height: auto;
  }

.titulo-col-datos{
    width: auto;
    height: 100%;
    @media only screen and (max-width: 900px), only screen and (max-height: 500px) {
        margin-top: 5vh;
        margin-left: 5vw;
        margin-bottom: 5vh;
    }
}

.titulo-texto{
    text-align: center;
    font-weight: bold;
    font-size: large;
    @media only screen and (max-width: 900px), only screen and (max-height: 500px) {
       font-size:medium;
    }
}

.subtitulo-texto{
    color:rgb(98, 98, 98);
    font-size: medium;
    @media only screen and (max-width: 900px), only screen and (max-height: 500px) {
        font-size:small;
    }
}

.texto-email{
    font-size: small;
    @media only screen and (max-width: 900px), only screen and (max-height: 500px){
        font-size: medium;
    }
}

.titulo-contenedor{
    height: 2vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid gray;
    border-radius: 20px;
    padding-left: 2vw;
    padding-right: 2vw;
    margin-top: 3vw;
    cursor: pointer;
    @media only screen and (max-width: 900px), only screen and (max-height: 500px) {
        padding-top: 2vw;
        padding-bottom: 2vw;
    }
  }
  .titulo-contenedor:hover {
    background-color: rgb(190,190,190);
    color:blue;
}

