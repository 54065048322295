
.contenedor-pagina{
  position:relative;
  min-height: 100%;
}
.app-container {
  display: flex;
  height: 95vh;
  text-align: center;
  
}

.footer {
  height: 6vh;
  line-height: normal;
  background-color: #333;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto; /* Alinea el pie de página en la parte inferior */
  position:fixed;
  bottom:0;
  left: 0;
  width: 100%;
  min-height: 6vh;
  font-size: small;
}

/* Media queries para hacer el diseño responsive */
@media only screen and (max-width: 900px), only screen and (max-height: 500px){
  .app-container {
    flex-direction: column;
  }
 .footer{
  font-size:xx-small;
 }
}