
.modal-overlay {
  position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    
}

.modal-content-w {
  background-color: #fff; 
  border-radius: 8px;
  outline: none;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*max-width: 80%;
  max-height: 80%;*/
  height: 65vh;
  width: 50vw;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.boton-w{
  color:black;
  text-decoration: none;
  width: 50%;   
  border: 1px solid gray; 
  border-radius: 20px;
  padding: 10px;
  margin-top: 20px;
  
}

.boton-w:hover {
  color: white;
  text-decoration: underline;
  background-color: rgb(190,190,190);
  border: 1px solid gray;
}

@media only screen and (max-width: 900px) {
  .modal-content-w{
    font-size: x-small;
    height: 75vh;
    width: 70vw;
  }
  @media (orientation: landscape) {
    .modal-content-w{
      font-size: x-small;
      height: 75vh;
      width: 85vw;
    }
    .modal-content-w h3{
      font-size: small;
    }
  }
}