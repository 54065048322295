.slideshow {
    margin: 0 auto;
    overflow: hidden;
    max-width: 1000px;
    padding-top: 20px;
     
  }
  
  .slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
   
  }
  
  .slide {
    display: inline-block;
    height: 700px;
    width: 100%;
    border-radius: 40px;
    
    text-align: center; /* Centrar contenido horizontalmente */
    
  }
  
  
  
  .slide p{
    width: 100%;  
    font-size: min(3vw, 3vh);
    color: black;
  }

  .image-in-div {
    width: 90%;
    height: 90%;
    object-fit: cover; /* or use 'contain' if you prefer */
    object-position: center; /* optional: center the image if it's smaller than the container */
    border-radius: 20px;
    display: inline-block;
   
  }

  /* Buttons */
  .slide{
    
  }
  .slide-left{
    align-self: flex-start;
    
  }

  .slide-right{
    align-self: flex-end;
    
  }
  .slide-center{
    align-self: center;
    
  }
  
  .slideshowDots {
    text-align: center;
    
    display: flex;
    justify-content: space-between;
  }
  
  .slideshowDot {
    display: inline-block;
    height: 2vw;
    width: 2vw;
    border-radius: 20%;
  
    cursor: pointer;
    margin: 1vw 0.5vw 0px;
  
    background-color: rgba(150,150,150, 0.9);;
    padding: 0.3vw;
  }

  .slideshowDot-double-left {
    margin-left: 5vh;
  }
  .slideshowDot-double-right {
    margin-right: 5vh;
  }
  
  .slideshowDot.active {
    background-color: rgba(190,190,190, 0.9);
  }
  

  .slideshowDot:hover {
    background-color: rgba(190,190,190, 0.4);
  }