
 

.mosaic-container {
  column-count: 3; /* Change this value to set the number of columns */
  column-gap: 10px;
  
  
}

.mosaic-item {
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
  overflow: hidden;
}

.mosaic-item img:hover {
  cursor: pointer;
  border-color: blue;
  transform: scale(0.95);
  border-radius: 8px;
}

  .mosaic-item img {
    width: 90%;
    height: auto;
    border-radius: 8px;
    border: 2px solid gray;
    transition: transform 0.3s;
  }

  .LazyLoad {
    opacity: 0;
    transition: all 1s ease-in-out;
  }
  
  .is-visible {
    opacity: 1;
  }



  .image-container {
    position: relative;
  }
  
  .image-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
  }

  @media only screen and (max-width: 900px), only screen and (max-height: 500px){
    .mosaic-container {
      column-count: 1; /* Change this value to set the number of columns */
      column-gap: 0px;
      
    }
  }