.large-image-modal {
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 1);
  }
  
  .modal-content {
    
    margin: 0 auto;
    padding: 10px;
    
   
    width: 100%;
    height: 100%;
    
    max-width: 800px;
    max-height: 850px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    overflow-y: auto;
    border: 2px solid gray;
    border-radius: 5px;
  }

  .modal-content div{
    text-align: center; 
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    
  }

  .modal-content div img {
    width: 100%; 
    height: 100%; 
  }
  
  .close {
    color: white;
    float: right;
    transition: transform 0.3s;
    
    margin-top: 30px;
    margin-right: 40px; 
    
  }
  
  
  .foot-modal{
    height: 4vh;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    color: white;
    

    justify-content: center; 
    align-items: center; 
  }

  .foot-modal-izq{
    height: 100%;
    margin-right: 5vw;
  }
  .foot-modal-der{
    height: 100%;
    margin-left: 5vw;
  }

  .close:hover,
  .close:focus,
  .foot-modal-izq:hover, 
  .foot-modal-der:hover {
    
    color: dimgray;
    text-decoration: none;
    cursor: pointer;
    transform: scale(1.2);
    
  }
